<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Form
        ref="searchWhere"
        :model="searchWhere"
        :label-width="100"
        label-position="right"
        @submit.native.prevent>
        <Row type="flex" :gutter="24">
          <Col v-bind="grid">
            <FormItem label="数据搜索：" label-for="status2">
              <Input search enter-button placeholder="请输入ID,KEY,数据组名称,简介" v-model="searchWhere.title" @on-search="handleSearchs"/>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex">
          <Col v-bind="grid">
            <Button type="primary" icon="md-add" @click="handleGroupCreate('添加数据组')" class="mr20">添加数据组</Button>
          </Col>
        </Row>
      </Form>

      <Table
        :columns="columns"
        :data="dataList"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="statuss">
          <i-switch
            v-model="row.status"
            :value="row.status"
            :true-value="1"
            :false-value="0"
            @on-change="handleChangeIsShow(row)"
            size="large">
            <span slot="open">显示</span>
            <span slot="close">隐藏</span>
          </i-switch>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <a @click="handleGoList(row)">数据列表</a>
          <Divider type="vertical" />
          <a @click="handleEdit(row, '编辑')">编辑</a>
          <Divider type="vertical" />
          <a class="l-color-error" @click="handleDelete(row, '删除数据组', index)">删除</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit"/>
      </div>
    </Card>

    <!-- 新增 编辑-->
    <group-from
      ref="groupfroms"
      :titleFrom="titleFrom"
      :groupId="groupId"
      :addId="addId"
      @getList="getGroupListRequest"></group-from>
  </div>
</template>

<script>
import mixins from '@/mixins'
import config from '@/config'
import {
  getGroupListApi
} from '@/api/system'
import GroupFrom from './groupFrom.vue'

export default {
  name: 'index',
  mixins: [mixins],
  components: {
    GroupFrom
  },
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15,
        title: ''
      },

      // 表格
      dataList: [],
      columns: [
        { title: 'ID', key: 'id', width: 80 },
        { title: 'KEY', key: 'config_name', minWidth: 130 },
        { title: '数据组名称', key: 'name', minWidth: 130 },
        { title: '简介', key: 'info', minWidth: 130 },
        { title: '操作', slot: 'action', fixed: 'right', width: 180 }
      ],

      FromData: null,
      titleFrom: '',
      groupId: 0,
      addId: ''
    }
  },
  mounted () {
    this.getGroupListRequest()
  },
  methods: {
    // 获取分组列表
    getGroupListRequest () {
      this.loading = true
      getGroupListApi(this.searchWhere).then(async (res) => {
        let data = res.data
        this.dataList = data.list
        this.total = data.count
        this.loading = false
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 处理跳转子页面
    handleGoList (row) {
      this.$router.push({
        path: config.routePre + '/system/config/system_group/list/' + row.id
      })
    },
    // 处理添加数据组
    handleGroupCreate (title) {
      this.$refs.groupfroms.modals = true
      this.titleFrom = title
      this.addId = 'addId'
      this.groupId = 0
    },
    // 处理编辑
    handleEdit (row, title) {
      this.titleFrom = title
      this.groupId = row.id
      this.$refs.groupfroms.fromData(row.id)
      this.$refs.groupfroms.modals = true
      this.addId = ''
    },
    // 处理删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `setting/group/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.dataList.splice(num, 1)
        this.getGroupListRequest()
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理状态搜索
    handleChangeIsShow () {},
    // 处理搜索
    handleSearchs () {
      this.searchWhere.page = 1
      this.getGroupListRequest()
    },
    // 处理分页
    handlePageChange (index) {
      this.searchWhere.page = index
      this.getGroupListRequest()
    }
  }
}
</script>

<style scoped>

</style>
